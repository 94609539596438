import { render, staticRenderFns } from "./Userform.vue?vue&type=template&id=fcb989f6&"
import script from "./Userform.vue?vue&type=script&lang=js&"
export * from "./Userform.vue?vue&type=script&lang=js&"
import style0 from "./Userform.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\wamp64\\www\\lifebase-front\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('fcb989f6')) {
      api.createRecord('fcb989f6', component.options)
    } else {
      api.reload('fcb989f6', component.options)
    }
    module.hot.accept("./Userform.vue?vue&type=template&id=fcb989f6&", function () {
      api.rerender('fcb989f6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/router/views/base/settings/users/components/Userform.vue"
export default component.exports